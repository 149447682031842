import React, { useEffect, useState } from "react";
import { useWindowSize } from "../../hooks/useWindowSize";
import { Button } from "../Button";
import { Loader } from "../Loader";
import CloseIcon from "../CloseIcon";
import Field from "../Form/Field";
import { Formik, Form } from "formik";
import PerfectScrollbar from "react-perfect-scrollbar";
import * as Yup from "yup";
import WhiteWave from "../../images/white-wave.png";
import logoImg from "../../images/instapay-logo-white.png";
import popupImage from "../../images/popup/special-offer.png";
import { ConsentCheckbox } from "../Form/ConsentCheckbox";

const FormSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Imię musi musi zawierać przynajmniej dwa znaki.")
    .required("Imię jest wymagane."),
  email: Yup.string()
    .email("Adres email ma nieprawidłowy format.")
    .required("Adres email jest wymagany."),
  consent: Yup.boolean()
    .oneOf([true], "Zgoda na przetwarzanie danych osobowych jest wymagana.")
    .required("Zgoda na przetwarzanie danych osobowych jest wymagana."),
});

export const PopupSpecialOffer = () => {
  const windowSize = useWindowSize();
  const [isOpen, setIsOpen] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [isSended, setIsSended] = useState(false);
  const [popupRef, setPopupRef] = useState(null);
  const [popupTop, setPopupTop] = useState(0);
  const [popupMinHeight, setPopupMinHeight] = useState(240);
  const [imageWidth, setImageWidth] = useState(400);
  const [popupContentHeight, setPopupContentHeight] = useState("auto");

  useEffect(() => {
    if (!windowSize.height || !popupRef) {
      return;
    }

    const refPopupRect = popupRef.getBoundingClientRect();

    if (windowSize.width < 800 || windowSize.height < 600) {
      setPopupMinHeight(windowSize.height - 30);
      setPopupContentHeight(`${windowSize.height - 60}px`);
    }

    const top = (windowSize.height - refPopupRect.height) / 2;

    if (windowSize.width >= 800 && windowSize.height > 600) {
      setPopupContentHeight(`${refPopupRect.height - 60}px`);
    }

    if (windowSize.width < 1400) {
      setImageWidth(260);
    }

    if (windowSize.width > 1400) {
      setImageWidth(400);
    }

    if (top < 0) {
      setPopupTop(0);

      return;
    }

    setPopupTop(top);
  }, [popupRef, windowSize.width]);

  const handleSubmit = async (values) => {
    setIsSending(true);

    try {
      let resp = await fetch(
        "https://europe-west1-instapay-prod.cloudfunctions.net/apiv2/popup-special-offer",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            withCredentials: true,
          },
          body: JSON.stringify(values),
        }
      );

      await resp.json();

      setTimeout(() => {
        setIsOpen(false);
      }, 4000);

      setIsSended(true);
      setIsSending(false);
    } catch (error) {
      console.error(error);
      setIsSending(false);
    }
  };

  const handleClose = () => setIsOpen(false);

  return isOpen ? (
    <>
      <div
        className="position-fixed w-100"
        style={{ zIndex: 101, top: `${popupTop}px` }}
        ref={(el) => setPopupRef(el)}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <div
                className="w-100 max-w-75rem h-auto h-lg-37_5rem bg-blue-gradient position-relative shadow-popup"
                style={{ minHeight: `${popupMinHeight}px` }}
              >
                <img
                  className="w-100 position-absolute eb-0"
                  src={WhiteWave}
                  alt=""
                />
                <img
                  src={popupImage}
                  alt="Zostaw po sobie ślad i zyskaj więcej"
                  className="position-absolute d-none d-lg-flex et-9 er-5"
                  style={{ width: `${imageWidth}px` }}
                />
                <div className="mx-lg-4rem py-3rem position-relative">
                  <CloseIcon
                    onClick={handleClose}
                    isOpen={true}
                    colorClass="bgc-white bc-white"
                    iconClass="position-absolute er-1 er-lg-0 et-1 et-lg-3"
                  />
                  {isSended ? (
                    <>
                      <img
                        src={logoImg}
                        alt="Instapay Logo"
                        className="w-5rem pb-4"
                      />
                      <div className="fs-2_5 font-weight-bold c-white pb-2rem">
                        Super! Udało się.
                      </div>
                      <div className="c-white max-w-lg-60 pb-3rem">
                        Dodaliśmy Cię do naszej sekretnej listy. Nasz konsultant
                        wkrótce się z Tobą skontaktuje i przedstawi możliwie
                        najkorzystniejszą ofertę i zaproponuje prezent.
                      </div>
                      <div
                        onClick={() => handleClose()}
                        className="btn bgc-navy-blue c-white bc-navy-blue round-2rem text-decoration-none font-weight-bold py-2 px-2_5rem"
                      >
                        Zamknij
                      </div>
                    </>
                  ) : (
                    <PopupForm
                      popupContentHeigh={popupContentHeight}
                      handleSubmit={handleSubmit}
                      isSending={isSending}
                      handleClose={handleClose}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`popupOverlay popupOverlay--open`} />
    </>
  ) : (
    <></>
  );
};

const PopupForm = ({
  popupContentHeight,
  handleSubmit,
  isSending,
  handleClose,
}) => (
  <>
    <div className="col-12 col-lg-9">
      <PerfectScrollbar>
        <div
          style={{ height: popupContentHeight }}
          className="mx-1rem mx-lg-0rem"
        >
          <img src={logoImg} alt="Instapay Logo" className="w-5rem pb-4" />
          <div className="fs-2 font-weight-bold c-white">
            Nie uciekaj tak szybko! Mamy dla Ciebie prezent.
          </div>
          <div className="c-white pb-4 max-w-lg-60">
            Zostaw do siebie kontak, a nasz konsultant odezwie się do Ciebie i
            zaproponuje specjalną ofertę-niespodziankę.
          </div>
          <Formik
            initialValues={{
              name: "",
              email: "",
              consent: false,
            }}
            validationSchema={FormSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <Field
                  id="name"
                  name="name"
                  placeholder="Imię"
                  errors={errors}
                  touched={touched}
                />
                <Field
                  id="email"
                  name="email"
                  placeholder="Email"
                  type="email"
                  errors={errors}
                  touched={touched}
                />
                <ConsentCheckbox
                  errors={errors}
                  touched={touched}
                  borderColorActive="bc-white"
                  colorActive="c-white"
                  name="consent"
                  text="Wyrażam zgodę na przetwarzanie przez Cashless Solutions Sp. z o.o. moich danych osobowych w postaci imienia, nazwiska adresu poczty elektronicznej oraz numeru telefonu w celu przesyłania mi informacji marketingowych dotyczących produktów i usług oferowanych przez Cashless Solutions sp. z o.o. za pomocą środków komunikacji elektronicznej oraz za pomocą urządzeń telefonicznych, stosownie do treści przepisu art. 10 ust. 1 i 2 ustawy o świadczeniu usług drogą elektroniczną."
                />
                <Button
                  htmlType="submit"
                  bgColor={10}
                  color={1}
                  borderColor={10}
                  round={4}
                  className="d-flex align-items-center py-2 text-uppercase px-2_5rem fs-0_875 fs-sm-1 text-decoration-none font-weight-bold"
                >
                  {isSending ? <Loader /> : "Odbierz prezent"}
                </Button>
                <div className="pb-4 pl-2_5rem pt-4">
                  <span
                    onClick={() => handleClose()}
                    className="text-underline c-navy-blue fs-0_875 cursor-pointer"
                  >
                    Nie chcę prezentu
                  </span>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </PerfectScrollbar>
    </div>
  </>
);
