import React from "react";
import { getRound } from "../utils/classPicker";
import { getPathname } from "../utils/location";
import { AnchorLink } from "gatsby-plugin-anchor-links";

export const LinkButton = ({
  children,
  text,
  url = "",
  stripHash,
  backgroundColor = "",
  borderColor = "",
  borderWidth = "",
  textColor = "",
  className = "",
  round = "",
  outline = "",
}) => {
  const btnRound = getRound(round);
  const outlineClass = outline === "none" ? "" : outline;
  const buttonClass = `btn ${outlineClass} ${backgroundColor} ${borderColor} ${borderWidth} ${textColor} ${className} ${btnRound} text-decoration-none font-weight-bold`;

  if (url.startsWith("#")) {
    url = `${getPathname()}${url}`;
  }

  return (
    <AnchorLink className={buttonClass} to={url} stripHash={stripHash}>
      {text ? text : children}
    </AnchorLink>
  );
};
