import { LinkButton } from "./LinkButton";
import React from "react";

export default ({ button, className }) => (
  <LinkButton
    {...button}
    round={4}
    className={`w-100 w-sm-auto mb-2rem py-2 text-uppercase px-3rem fs-0_875 fs-sm-1 ${className}`}
  />
);
