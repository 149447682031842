import React from "react";
import {
  getBgColor,
  getBorderColor,
  getColor,
  getRound,
} from "../utils/classPicker";

export const Button = ({
  bgColor = "",
  color = "",
  round = "",
  borderColor = "",
  outline = "",
  children,
  className = "",
  htmlType,
    onClick
}) => {
  const btnRound = getRound(round);
  const btnBgColor = getBgColor(bgColor);
  const btnColor = getColor(color);
  const btnBorderColor = getBorderColor(borderColor);
  const buttonClass = `btn ${outline} ${btnBgColor} ${btnColor} ${btnBorderColor} ${className} ${btnRound} text-decoration-none font-weight-bold`;
  return (
    <button type={htmlType} className={buttonClass} onClick={onClick}>
      {children}
    </button>
  );
};
