import React, { useState } from "react";
import { RichText } from "./RichText";
import PrimaryLinkButton from "./PrimaryLinkButton";
import { getRound } from "../utils/classPicker";
import { ContactForm } from "./Form/ContactForm";
import { Loader } from "./Loader";
import textFormatter from "../utils/text";
import messages from "../utils/messages";
import tick from "../images/icon/tick.png";
import { LinkButton } from "./LinkButton";

export const SocialItems = ({ items }) => (
  <div className="d-flex flex-wrap justify-content-center justify-content-lg-end pb-4">
    {items.map(({ icon, alt, url }, index) => (
      <a
        className="c-white pl-0_75rem"
        href={url}
        target="_blank"
        rel="noreferrer noopener"
        download
        key={index}
      >
        <img src={icon.fluid.src} alt={alt} />
      </a>
    ))}
  </div>
);

export const ContactEmail = ({ contactEmail }) => (
  <div className="text-center text-lg-right mb-1rem">
    <a className="c-white" href={`mailto:${contactEmail}`}>
      {contactEmail}
    </a>
  </div>
);

export const Copyrights = ({ copyrights, className }) => (
  <RichText
    text={copyrights?.raw}
    className={`rich-text__raw c-white ${className}`}
  />
);

export const TextColumn = ({
  title,
  description,
  buttons,
  smallText,
  separator,
  separatorSize,
  className,
  aosOffset,
  position = "left",
  iconClass = "",
  leftColWidth = 6,
  isPromotionalVideo = false,
}) => {
  const positionClass = position === "left" ? "order-lg-1" : "order-lg-12";
  const alignClass = isPromotionalVideo ? "text-center" : "";
  const descriptionWidthClass = isPromotionalVideo ? "d-inline-block w-70" : "";
  let colWidth = leftColWidth;

  if (position !== "left") {
    colWidth = 12 - parseInt(leftColWidth);
  }

  if (isPromotionalVideo) {
    colWidth = 12;
  }

  return (
    <div
      className={`col-12 col-lg-${
        colWidth || 6
      } d-flex flex-wrap align-items-center order-1 ${positionClass} ${alignClass}`}
    >
      <div className={`${className || ""}`}>
        {title && (
          <h2
            className={`fs-1_75 font-weight-bold ${separator ? "m-0" : "pb-2"}`}
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-offset={aosOffset || 0}
          >
            {title}
          </h2>
        )}
        {separator && (
          <div
            className={`py-${separatorSize}rem`}
            data-aos="fade-left"
            data-aos-delay="100"
            data-aos-offset={aosOffset || 0}
          >
            <div
              className={`border-top bw-2 bc-blue`}
              style={{ maxWidth: "154px" }}
            />
          </div>
        )}
        {description && (
          <div
            className={`pb-1rem ${descriptionWidthClass}`}
            data-aos="fade-up"
            data-aos-delay="150"
            data-aos-offset={aosOffset || 0}
          >
            <RichText
              text={description.raw}
              formatOptions={{
                checkIcon: {
                  iconClass: `fs-1_5 sw-2 mt-1 ${iconClass}`,
                  textClass: "pl-3",
                },
              }}
            />
          </div>
        )}
        {smallText && (
          <div
            className="fs-0_875 pb-1rem"
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-offset={aosOffset || 0}
          >
            <RichText text={smallText.raw} />
          </div>
        )}
        {buttons && <Buttons buttons={buttons} className="d-none d-lg-block" />}
      </div>
    </div>
  );
};

export const ContactColumn = ({
  title,
  consents,
  button,
  description,
  smallText,
  successMessage = "",
  separator,
  separatorSize,
  formActionUrl,
  fieldWidth,
  className = "col-12 col-lg-6",
  position = "left",
  before = <></>,
  style,
  isSent,
  setIsSent,
}) => {
  const [isSending, setIsSending] = useState(false);
  const [isSendFormError, setIsSendFormError] = useState(false);
  const [sendFormErrorMessage, setSendFormErrorMessage] = useState(null);
  const positionClass =
    position === "left" ? "order-lg-1" : "order-1 order-lg-12";

  const handleSubmit = async (values) => {
    setIsSending(true);

    if (!formActionUrl) {
      setIsSending(false);
      setIsSent(true);

      return;
    }

    try {
      setIsSent(true);
      let resp = await fetch(formActionUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          withCredentials: true,
        },
        body: JSON.stringify(values),
      });

      const response = await resp.json();

      if (response.status === "error") {
        const errorMessage = messages.getErrorMessage(response.type);
        setIsSendFormError(true);
        setSendFormErrorMessage(errorMessage);
      } else {
        setIsSent(true);
      }

      setIsSending(false);
    } catch (error) {
      setIsSendFormError(true);
      setSendFormErrorMessage(
        "Nie udało się wysłać wiadomości - spróbuj ponownie później."
      );
      setIsSending(false);
    }
  };

  return (
    <div className={`${className} ${positionClass}`} style={style}>
      {isSent ? (
        <SuccessMessage message={successMessage} />
      ) : (
        <ContactColumnContent
          before={before}
          title={title}
          separator={separator}
          separatorSize={separatorSize}
          description={description}
          isSendFormError={isSendFormError}
          sendFormErrorMessage={sendFormErrorMessage}
          handleSubmit={handleSubmit}
          isSending={isSending}
          consents={consents}
          button={button}
          fieldWidth={fieldWidth}
          smallText={smallText}
        />
      )}
    </div>
  );
};

const ContactColumnContent = ({
  before,
  title,
  separator,
  separatorSize,
  description,
  isSendFormError,
  sendFormErrorMessage,
  handleSubmit,
  isSending,
  consents,
  button,
  fieldWidth,
  smallText,
}) => (
  <>
    {before}
    {title && (
      <h2
        className={`fs-1_75 font-weight-bold pb-1_5rem`}
        data-aos="fade-up"
        data-aos-delay="50"
      >
        {title}
      </h2>
    )}
    {separator && (
      <div
        className={`py-${separatorSize}rem`}
        data-aos="fade-left"
        data-aos-delay="100"
      >
        <div
          className={`border-top bw-2 bc-blue`}
          style={{ maxWidth: "154px" }}
        />
      </div>
    )}
    {description && (
      <div className="pb-1rem" data-aos="fade-up" data-aos-delay="150">
        <RichText
          text={description.raw}
          formatOptions={{
            phone: true,
          }}
        />
      </div>
    )}
    {isSendFormError && <div>{sendFormErrorMessage}</div>}
    <ContactForm
      handleSubmit={handleSubmit}
      isSending={isSending}
      consents={consents}
      button={button}
      colorActive="c-black"
      borderColorActive="bc-black"
      backgroundColorActive="bgc-white"
      fieldWidth={fieldWidth}
    />
    {smallText && (
      <div className="fs-0_875 pb-1rem" data-aos="fade-up" data-aos-delay="200">
        <RichText text={smallText.raw} />
      </div>
    )}
  </>
);

const SuccessMessage = ({ message }) => (
  <>
    <div className="text-center pb-2rem">
      <img src={tick} alt="instapay tick shape" />
    </div>
    {message ? (
      <RichText
        className="pb-2rem"
        text={message.raw}
        formatOptions={{
          bold: true,
          aCenter: true,
        }}
      />
    ) : (
      <div className="pb-2rem font-weight-bold">Wiadmość wysłana</div>
    )}
  </>
);

export const ImageColumn = ({
  image,
  position,
  imageAlign,
  title,
  description,
  className,
  buttons,
  imageClass = "d-inline-block max-w-100 px-0",
  colClassName = "col-12 order-11 col-lg-6",
  before = <></>,
}) => {
  imageClass = `pr-2rem ${imageClass}`;

  if (position === "right") {
    imageClass = `pl-2rem ${imageClass}`;
  }

  return (
    <div className={colClassName}>
      <div className="d-flex flex-wrap align-items-center">
        <div className={`d-flex flex-wrap ${className || ""}`}>
          {before}
          {title && (
            <h2
              className="fs-1_75 font-weight-bold pb-2"
              data-aos="fade-up"
              data-aos-delay="50"
            >
              {title}
            </h2>
          )}
          {description && (
            <div className="pb-1rem" data-aos="fade-up" data-aos-delay="150">
              <RichText text={description.raw} />
            </div>
          )}
          {buttons && (
            <Buttons buttons={buttons} className="d-none d-lg-block" />
          )}
        </div>
      </div>
      {image && (
        <div className={`text-center text-lg-${imageAlign || "center"}`}>
          <img
            className={imageClass}
            src={image.fluid.src}
            alt="section three"
            data-aos="fade-up"
          />
        </div>
      )}
    </div>
  );
};

export const Buttons = ({ buttons, className }) => {
  return (
    <div className={className} data-aos="fade-up" data-aos-delay="200">
      {buttons.map((button, index) =>
        buttons.length > 1 ? (
          <LinkButton
            {...button}
            round={4}
            className="w-100 w-sm-auto mb-2rem py-2 text-uppercase px-1_5rem fs-0_875 mr-1rem"
            key={index}
          />
        ) : (
          <PrimaryLinkButton
            button={button}
            key={index}
            className="mr-0 mr-sm-2rem"
          />
        )
      )}
    </div>
  );
};

export const SubmitButton = ({
  text,
  backgroundColor = "",
  borderColor = "",
  borderWidth = "",
  textColor = "",
  className = "",
  round = "",
  outline = "",
  isSending = false,
}) => {
  const btnRound = getRound(round);
  const outlineClass = outline === "none" ? "" : outline;
  const buttonClass = `btn ${outlineClass} ${backgroundColor} ${borderColor} ${borderWidth} ${textColor} ${className} ${btnRound} text-decoration-none font-weight-bold`;

  return (
    <button type="submit" className={buttonClass}>
      {isSending ? <Loader /> : textFormatter.format(text)}
    </button>
  );
};
