import React, { useEffect } from "react";
import AOS from "aos";
import { usePopupMain } from "./usePopupMain";
import { usePopupSpecialOffer } from "./usePopupSpecialOffer";
import { useSpecialOfferRedirect } from "./useSpecialOfferRedirect";

export const usePageInit = () => {
  useSpecialOfferRedirect();
  const popupMain = usePopupMain();
  const popupSpecialOffer = usePopupSpecialOffer();

  useEffect(() => {
    AOS.init();
  }, []);

  return { popup: popupMain || popupSpecialOffer };
};
