export const getErrorMessage = (errorType) => {
  switch (errorType) {
    case "user_already_in_list":
      return "Twój e-mail jest już w bazie. Jeśli nikt wciąż się z Tobą nie skontaktował, prosimy o chwilę cierpliwości. Odezwiemy się do Ciebie na pewno.";
  }

  return "Wystąpił problem - spróbuj ponownie później.";
};

export default {
  getErrorMessage,
};
