export const getBgColor = (color) => {
  switch (color) {
    case 0:
      return "bgc-black";
    case 1:
      return "bgc-white";
    case 2:
      return "bgc-blue";
    case 3:
      return "bgc-blue-light";
    case 4:
      return "bgc-blue-dark";
    case 5:
      return "bgc-cyan";
    case 6:
      return "bgc-gray-100";
    case 7:
      return "bgc-gray-200";
    case 8:
      return "bgc-gray-300";
    case 9:
      return "bgc-almost-white";
    case 10:
      return "bgc-navy-blue";
    default:
      return "";
  }
};

export const getColor = (color) => {
  switch (color) {
    case 0:
      return "c-black";
    case 1:
      return "c-white";
    case 2:
      return "c-blue";
    case 3:
      return "c-blue-light";
    case 4:
      return "c-blue-dark";
    case 5:
      return "c-cyan";
    case 6:
      return "c-gray-100";
    case 7:
      return "c-gray-200";
    case 8:
      return "c-gray-300";
    case 9:
      return "c-almost-white";
    case 10:
      return "c-navy-blue";
    default:
      return "";
  }
};

export const getBorderColor = (color) => {
  switch (color) {
    case 0:
      return "bc-black";
    case 1:
      return "bc-white";
    case 2:
      return "bc-blue";
    case 3:
      return "bc-blue-light";
    case 4:
      return "bc-blue-dark";
    case 5:
      return "bc-cyan";
    case 6:
      return "bc-gray-100";
    case 7:
      return "bc-gray-200";
    case 8:
      return "bc-gray-300";
    case 9:
      return "bc-almost-white";
    case 10:
      return "bc-navy-blue";
    default:
      return "";
  }
};

export const getBoldColor = (color) => {
  switch (color) {
    case 0:
      return "cbold-black";
    case 1:
      return "cbold-white";
    case 2:
      return "cbold-blue";
    case 3:
      return "cbold-blue-light";
    case 4:
      return "cbold-blue-dark";
    case 5:
      return "cbold-cyan";
    case 6:
      return "cbold-gray-100";
    case 7:
      return "cbold-gray-200";
    case 8:
      return "cbold-gray-300";
    case 9:
      return "cbold-almost-white";
    case 10:
      return "cbold-navy-blue";
    default:
      return "";
  }
};

export const getRound = (round) => {
  switch (round) {
    case 0:
      return "rounded-0";
    case 1:
      return "rounded-sm";
    case 2:
      return "rounded-lg";
    case 3:
      return "round-1rem";
    case 4:
      return "round-2rem";
    case 5:
      return "round-3rem";
    default:
      return "";
  }
};
