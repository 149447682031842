import { Helmet } from "react-helmet";
import React from "react";
import { getPathname } from "../utils/location";
import { truncate } from "../utils/text";
import defaultImage from "../images/instapay_share.png";

const defaultDescription =
  "Instapay - system CRM, dzięki któremu automatycznie, bezpiecznie i wygodnie pobierasz płatności cykliczne oraz jednorazowe od klientów. Skończ z windykacją, przypomnieniami i opóźnieniami i zyskaj czas na rozwój firmy!";

const AppHelmet = ({
  title = "Instapay - pobieraj płatności zawsze w terminie",
  description = "",
  image = `https://instapay.pl${defaultImage}`,
}) => {
  const trimedTitle = title.trim();
  const trimedDescription = description
    ? truncate(description.trim(), 150)
    : defaultDescription;

  return (
    <Helmet>
      <title>
        {title && getPathname() !== "/"
          ? `${trimedTitle} - Instapay`
          : `Instapay`}
      </title>
      <meta property="og:site_name" content="Instapay" />
      <meta property="og:title" content={trimedTitle} />
      <meta property="og:description" content={trimedDescription} />
      <meta property="og:image" content={image} />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:type" content="website" />
      <meta name="description" content={trimedDescription} />
      <meta name="theme-color" content="#ffffff" />
      <meta
        property="article:modified_time"
        content="2021-04-15T00:00:00+02:00"
      />
      <meta property="og:title" content={trimedTitle} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={trimedTitle} />
      <meta name="twitter:description" content={trimedDescription} />
      <meta name="twitter:image" content={image} />
      <meta
        name="facebook-domain-verification"
        content="zgly7a24vdr7lreawiwp70bylo22l7"
      />
      <script>
        {`(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2454968,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        _linkedin_partner_id = "3268410";
        window._linkedin_data_partner_ids = 
        window._linkedin_data_partner_ids || [];                             
       window._linkedin_data_partner_ids.push(_linkedin_partner_id);
        (function(l) { 
            if (!l){window.lintrk = function(a,b) 
        {window.lintrk.q.push([a,b])};
        window.lintrk.q=[]} var s = 
        document.getElementsByTagName("script")[0];
        var b = document.createElement("script");
        b.type = "text/javascript";
        b.async = true;
        b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
        s.parentNode.insertBefore(b, s);})(window.lintrk);`}
      </script>
      <script>{`var loadBabel = function(url, callback) {var script = document.createElement('script');script.async = true;if (script.readyState) {script.onreadystatechange = function() {if (script.readyState == 'loaded' || script.readyState == 'complete') {script.onreadystatechange = null;callback(window, document);}};} else {script.onload = function() {callback(window, document);};}script.src = url;document.head.appendChild(script);};var getCookie = function(cname) {var objToday = new Date();var version = objToday.toISOString().split('T')[0].split('-').join('');var name = cname + '=';var decodedCookie = decodeURIComponent(document.cookie);var cookieArray = decodedCookie.split(';');for (var i = 0; i < cookieArray.length; i++) {var cookie = cookieArray[i];cookie = cookie.trim();if (cookie.indexOf(name) == 0) {return cookie.substring(name.length, cookie.length);}}return version;};var loadWidget = function(window, document) {var __cp = {"id":"qKqNHbX45uIe_nasb3bNDjv7CBMShSCAiXPWhkVmEWQ","version":"1.1"};var cp = document.createElement('script');cp.type = 'text/javascript';cp.async = true;cp.src = "++cdn-widget.callpage.io+build+js+callpage.js".replace(/[+]/g, '/').replace(/[=]/g, '.') + '?v=' + getCookie('callpage-widget-version');var s = document.getElementsByTagName('script')[0];s.parentNode.insertBefore(cp, s);if (window.callpage) {alert('You could have only 1 CallPage code on your website!');} else {window.callpage = function(method) {if (method == '__getQueue') {return this.methods;} else if (method) {if (typeof window.callpage.execute === 'function') {return window.callpage.execute.apply(this, arguments);} else {(this.methods = this.methods || []).push({arguments: arguments,});}}};window.callpage.__cp = __cp;window.callpage('api.button.autoshow');}};loadBabel('https://cdnjs.cloudflare.com/ajax/libs/babel-polyfill/6.26.0/polyfill.min.js', function() {return loadWidget(window, document);});`}</script>
    </Helmet>
  );
};

export default AppHelmet;
