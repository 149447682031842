import React from "react";

const CheckIcon = ({ className }) => (
  <svg
    className={className}
    aria-hidden="true"
    focusable="false"
    role="img"
    aria-labelledby="check-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    id="check-svg"
  >
    <title id="check-icon">Check icon</title>
    <polyline points="20 6 9 17 4 12" />
  </svg>
);

export default CheckIcon;
