export const getPathname = () =>
  typeof window !== "undefined" ? window.location.pathname : "";

export const getLocalStorage = () =>
  typeof window !== "undefined"
    ? window.window.localStorage
    : {
        getItem: () => "",
        setItem: () => {},
      };

export const getParams = () => {
  const params = {};
  const location = window.location;

  if (typeof location.search === "undefined") {
    return params;
  }

  if (location.search.length < 2 && !location.search.startsWith("?")) {
    return params;
  }

  location.search
    .substr(1)
    .split("&")
    .forEach((item) => {
      params[item.split("=")[0]] = item.split("=")[1];
    });

  return params;
};

export default {
  getPathname,
  getParams,
};
