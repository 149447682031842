import React from "react";
import CheckIcon from "../component/Icon/CheckIcon";
import icon from "../../public/icons/icon-48x48.png";

export const shorten = (str, wordsNumber, separator = " ") => {
  return str.split(separator).splice(0, wordsNumber).join(separator);
};

export const truncate = (str, charsNumber, separator = " ") => {
  const truncatedStr = str.substring(0, charsNumber);
  const wordsNumber = truncatedStr.split(separator).length;
  const shortenStr = shorten(str, wordsNumber);

  return str.length > shortenStr.length ? `${shortenStr}...` : shortenStr;
};

const format = (str) => {
  let parts = formatNewLines(str);
  parts = parts.map((t) => formatBold(t));

  return <>{parts}</>;
};

const formatBold = (node) => {
  if (typeof node !== "string") {
    return node;
  }

  const parts = node.split(/(\*.*?\*)/gi);

  for (let i = 1; i < parts.length; i += 2) {
    parts[i] = (
      <span className="font-weight-bold" key={i}>
        {parts[i].split("*").join("")}
      </span>
    );
  }

  return <>{parts}</>;
};

const formatIconCheck = (node, { iconClass, textClass }) => {
  if (typeof node !== "string") {
    return node;
  }

  const parts = node.split(/^>/gi);

  for (let i = 1; i < parts.length; i += 2) {
    parts[i] = (
      <div className="d-flex" key={i}>
        <CheckIcon className={`svg-inline ${iconClass || ""}`} />
        <span className={textClass || ""}>{parts[i].split("*").join("")}</span>
      </div>
    );
  }

  return <>{parts}</>;
};

const formatIconCheckInline = (node, { iconClass, textClass }) => {
  if (typeof node !== "string") {
    return node;
  }

  if (node.includes("<<")) {
    const parts = node.split(/^<</gi);

    for (let i = 1; i < parts.length; i += 2) {
      parts[i] = (
          <div className="d-flex align-items-center">
            <img src={icon} alt="" className="w-5 h-5"/>
            <span className={textClass || ""}>{parts[i].split("*").join("")}</span>
          </div>
      );
    }

    return <>{parts}</>;
  }

  if (!node.includes(">>")) {
    return node;
  }

  const parts = node.split(/^>>/gi);

  for (let i = 1; i < parts.length; i += 2) {
    parts[i] = (
      <>
        <CheckIcon className={`svg-inline ${iconClass || ""}`} />
        <span className={textClass || ""}>{parts[i].split("*").join("")}</span>
      </>
    );
  }

  return <>{parts}</>;
};

const formatPhone = (node, { textClass }) => {
  if (typeof node !== "string") {
    return node;
  }

  const parts = node.split(/(\[tel].*?\[\/tel])/gi);

  for (let i = 1; i < parts.length; i += 2) {
    const tel = parts[i].split(/\[tel]|\[\/tel]/gi).join("");
    parts[i] = (
      <a href={`tel:${tel}`} className={textClass}>
        {tel}
      </a>
    );
  }

  return <>{parts}</>;
};

const formatAlignCenter = (node) => {
  if (typeof node !== "string") {
    return node;
  }

  const parts = node.split(/(\[aCenter].*?\[\/aCenter])/gi);

  for (let i = 1; i < parts.length; i += 2) {
    const text = parts[i].split(/\[aCenter]|\[\/aCenter]/gi).join("");
    parts[i] = <span className="text-center">{text}</span>;
  }

  return <>{parts}</>;
};

const formatNewLines = (text) =>
  text.split("\\n").flatMap((text, i) => [i > 0 && <br />, text]);

export default {
  shorten,
  truncate,
  format,
  formatIconCheck,
  formatIconCheckInline,
  formatPhone,
  formatBold,
  formatAlignCenter,
  formatNewLines,
};
