import { useEffect } from "react";
import { getLocalStorage, getPathname } from "../../utils/location";
import { navigate } from "gatsby";
const specialOfferPages = ["/oferta-promocyjna", "/oferta-promocyjna-2"];

export const useSpecialOfferRedirect = () => {
  useEffect(() => {
    const pathname = getPathname();
    const path = pathname.replace(/\/$/, "");

    if (!specialOfferPages.includes(path)) {
      return;
    }

    const isOfferVisited = isPageVisited("special-offer-visited");
    const isOffer2Visited = isPageVisited("special-offer-2-visited");

    if (isOfferVisited && isOffer2Visited) {
      return;
    }

    if (isOfferVisited && path === "/oferta-promocyjna-2") {
      return navigate("/oferta-promocyjna");
    }

    if (isOffer2Visited && path === "/oferta-promocyjna") {
      return navigate("/oferta-promocyjna-2");
    }

    if (path === "/oferta-promocyjna") {
      window.localStorage.setItem("special-offer-visited", "true");
    }

    if (path === "/oferta-promocyjna-2") {
      window.localStorage.setItem("special-offer-2-visited", "true");
    }
  }, []);
};

export const isPageVisited = (key) => {
  const val = getLocalStorage().getItem(key);

  return val === "true";
};
