import React from "react";
import { BLOCKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { v4 as uuidv4 } from "uuid";
import textFormatter from "../utils/text";

let embeddedAssetCount = 0;

let options = (references, blocks = {}, formatOptions = { listItem: "" }) => {
  return {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const ref = references[embeddedAssetCount];
        embeddedAssetCount++;

        return ref && <img className="w-100" src={ref.fluid.src} alt="" />;
      },
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="fs-1_75 font-weight-bold pb-2">{children}</h2>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <h5 className="fs-1_125 font-weight-bold">{children}</h5>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <li>
          {formatOptions?.listItem === "raw"
            ? node.content[0].content[0].value
            : children}
        </li>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => {
        const uuid = uuidv4();

        if (!blocks.paragraph) {
          return <p key={uuid}>{children}</p>;
        }

        if (!blocks.paragraph.className) {
          return <p key={uuid}>{children}</p>;
        }

        return (
          <p className={`${blocks.paragraph.className}`} key={uuid}>
            {children}
          </p>
        );
      },
    },
    renderText: (text) => {
      text = text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]);

      const { aCenter, checkIcon, phone, bold } = formatOptions;

      if (checkIcon) {
        text = text.map((t) =>
          textFormatter.formatIconCheckInline(t, checkIcon)
        );

        text = text.map((t) => textFormatter.formatIconCheck(t, checkIcon));
      }

      if (phone) {
        text = text.map((t) => textFormatter.formatPhone(t, phone));
      }

      if (bold) {
        text = text.map((t) => textFormatter.formatBold(t, bold));
      }

      if (aCenter) {
        text = text.map((t) => textFormatter.formatAlignCenter(t, aCenter));
      }

      return text;
    },
  };
};

export const RichText = ({
  text,
  references,
  className,
  blocks = {},
  formatOptions = {},
}) =>
  text ? (
    <div className={`${className || ""} rich-text`}>
      {documentToReactComponents(
        JSON.parse(text),
        options(references, blocks, formatOptions)
      )}
    </div>
  ) : (
    <></>
  );
