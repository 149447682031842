import React from "react";

const CloseIcon = ({ isOpen, onClick, colorClass, iconClass = "" }) => {
  const color = colorClass || "bgc-blue bc-blue";

  return (
    <div className={`d-flex ${iconClass}`} onClick={() => onClick(!isOpen)}>
      <div
        className={`b-menu cursor-pointer d-inline-block position-relative ${
          isOpen && "open"
        }`}
      >
        <div className={`b-bun b-bun--top ${color}`} />
        <div className={`b-bun b-bun--mid ${color}`} />
        <div className={`b-bun b-bun--bottom ${color}`} />
      </div>
    </div>
  );
};

export default CloseIcon;
