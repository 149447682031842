export default class Subject {
  constructor() {
    this.observers = [];
    this.observerIds = [];
  }

  attach(observer) {
    if (this.observerIds.includes(observer.id)) {
      return;
    }

    this.observers.push(observer);
    this.observerIds.push(observer.id);
  }

  detach(observer) {
    this.observers = this.observers.filter((obs) => obs !== observer);
    this.observerIds = this.observerIds.filter(
      (observerId) => observerId !== observer.id
    );
  }

  detachAll() {
    this.observers.map(({}, index) => this.observers.splice(index, 1));
    this.observerIds.map(({}, index) => this.observerIds.splice(index, 1));
  }

  notify(data) {
    if (this.observers.length > 0) {
      this.observers.forEach((observer) => observer.update(data));
    }
  }
}
