import React, { useEffect, useState } from "react";
import { getLocalStorage } from "../utils/location";

export const Cookies = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const val = getLocalStorage().getItem("show-cookies");

    if (val === "true") {
      return setShow(false);
    }

    setShow(true);
  });

  const handleOnClick = () => {
    getLocalStorage().setItem("show-cookies", "true");

    setShow(false);
  };

  return show ? (
    <div className="position-fixed eb-2 w-100">
      <div className="container">
        <div className="col-12 d-flex justify-content-center">
          <div className="bgc-white py-1rem px-1rem px-md-2rem cookies d-flex justify-content-between">
            <div className="pr-0_25rem pr-md-1_5rem pb-0_5rem pb-md-0 d-inline-flex align-items-center">
              <span>
                Ta strona używa plików cookies. Więcej informacji znajdziesz
                czytając naszą &nbsp;
                <a href="/polityka-prywatnosci">politykę prywatności</a>.
              </span>
            </div>
            <div className="d-flex align-items-center">
              <button
                className="btn bw-2 bc-black bgc-black c-white round-2rem btn-outline-transparent text-decoration-none font-weight-bold"
                children="ok"
                onClick={handleOnClick}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};
