import Subject from "./Subject";
import { showPopup } from "../utils/popup";

export const UPDATE_SHOW_POPUP_MAIN = "UPDATE_SHOW_POPUP_MAIN";
export const UPDATE_SHOW_POPUP_SPECIAL_OFFER =
  "UPDATE_SHOW_POPUP_SPECIAL_OFFER";

export default class UserVisitState extends Subject {
  constructor() {
    super();
    if (UserVisitState.instance instanceof UserVisitState) {
      return UserVisitState.instance;
    }

    this.settingsObject = {
      version: Math.floor(Math.random() * 4000 + Date.now()),
    };
    this.state = {
      showPopupMain: showPopup("hide-popup-main"),
      showPopupSpecialOffer: showPopup("hide-popup-special-offer"),
    };

    Object.freeze(this.settingsObject);
    Object.freeze(this);
    UserVisitState.instance = this;
  }

  runPopupMainCounter() {
    if (this.state.showPopupMain === false) {
      return;
    }

    let time = 0;

    const interval = setInterval(() => {
      time++;

      if (this.state.showPopupMain === false) {
        clearInterval(interval);
      }

      if (time === 6) {
        this.state.showPopupMain = false;
        window.localStorage.setItem("hide-popup-main", "true");
        this.notify({
          type: UPDATE_SHOW_POPUP_MAIN,
        });

        clearInterval(interval);
      }
    }, 1000);
  }

  updateShowPopupMain(showPopupMain) {
    this.state.showPopupMain = showPopupMain;

    if (showPopupMain === false) {
      window.localStorage.setItem("hide-popup-main", "true");
    }

    this.notify({
      type: UPDATE_SHOW_POPUP_MAIN,
    });
  }

  updateShowPopupSpecialOffer(showPopupSpecialOffer) {
    this.state.showPopupSpecialOffer = showPopupSpecialOffer;

    if (showPopupSpecialOffer === false) {
      window.localStorage.setItem("hide-popup-special-offer", "true");
    }

    this.notify({
      type: UPDATE_SHOW_POPUP_SPECIAL_OFFER,
    });
  }

  attach(observer) {
    this.detachAll();
    this.observers.push(observer);
    this.observerIds.push(observer.id);
  }
}
