import React from "react";
import Field from "../Form/Field";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ConsentCheckbox } from "./ConsentCheckbox";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import { SubmitButton } from "../Common";

const FormSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Imię musi musi zawierać przynajmniej dwa znaki.")
    .required("Imię jest wymagane."),
  phone: Yup.string().matches(/^[0-9+ ]*$/, "Telefon ma nieprawidłowy format."),
  email: Yup.string()
    .email("Adres email ma nieprawidłowy format.")
    .required("Adres email jest wymagany."),
  consent: Yup.boolean()
    .oneOf([true], "Zgoda na przetwarzanie danych osobowych jest wymagana.")
    .required("Zgoda na przetwarzanie danych osobowych jest wymagana."),
});

export const ContactForm = ({
  handleSubmit,
  isSending,
  consents,
  button,
  colorActive,
  borderColorActive,
  backgroundColorActive,
  fieldWidth,
  termsCheckbox,
  handleClose,
  isEbook,
}) => {
  const renderPhoneField = (errors, touched) => {
    return (
      <Field
        id="phone"
        name="phone"
        placeholder={isEbook ? "Telefon (opcjonalne)" : "Telefon"}
        errors={errors}
        touched={touched}
        color="c-black"
        borderColor="bc-black"
        colorActive={colorActive}
        borderColorActive={borderColorActive}
        backgroundColorActive={backgroundColorActive}
        width={fieldWidth}
      />
    );
  };

  const renderEmailField = (errors, touched) => {
    return (
      <Field
        id="email"
        name="email"
        placeholder={isEbook ? "Email (wymagane)" : "Email"}
        type="email"
        errors={errors}
        touched={touched}
        color="c-black"
        borderColor="bc-black"
        colorActive={colorActive}
        borderColorActive={borderColorActive}
        backgroundColorActive={backgroundColorActive}
        width={fieldWidth}
      />
    );
  };
  return (
    <Formik
      initialValues={{
        name: "",
        phone: "",
        email: "",
        consent: false,
      }}
      validationSchema={FormSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched }) => (
        <Form>
          <Field
            id="name"
            name="name"
            placeholder={isEbook ? "Imię (wymagane)" : "Imię"}
            errors={errors}
            touched={touched}
            color="c-black"
            borderColor="bc-black"
            colorActive={colorActive}
            borderColorActive={borderColorActive}
            backgroundColorActive={backgroundColorActive}
            width={fieldWidth}
          />
          {isEbook ? (
            <>
              {renderEmailField(errors, touched)}
              {renderPhoneField(errors, touched)}
            </>
          ) : (
            <>
              {renderPhoneField(errors, touched)}
              {renderEmailField(errors, touched)}
            </>
          )}
          {consents &&
            consents.map((consent) => (
              <ConsentCheckbox
                errors={errors}
                touched={touched}
                text={documentToPlainTextString(JSON.parse(consent.text.raw))}
                name="consent"
                color="c-black"
                borderColor="bc-black"
                colorActive={colorActive}
                borderColorActive={borderColorActive}
                backgroundColorActive={backgroundColorActive}
                width={fieldWidth}
              />
            ))}
          {termsCheckbox && (
            <ConsentCheckbox
              errors={errors}
              touched={touched}
              color="c-black"
              borderColor="bc-black"
              colorActive={colorActive}
              borderColorActive={borderColorActive}
              backgroundColorActive={backgroundColorActive}
              name="consent"
              width={100}
              text={termsCheckbox}
              fontSize="x-small"
            />
          )}

          <div
            className={
              termsCheckbox ? "text-center" : "text-center text-lg-left"
            }
          >
            <SubmitButton
              {...button}
              round={4}
              isSending={isSending}
              className={`w-100 w-sm-auto mb-1rem py-2 text-uppercase px-3rem fs-0_875 fs-sm-1`}
            />
            {handleClose && !isSending && (
              <p role="button" style={{ margin: "0" }} onClick={handleClose}>
                Zamknij
              </p>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};
