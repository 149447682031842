import React, { useState } from "react";
import { Field as FormikField } from "formik";

const Field = (props) => {
  const {
    errors,
    touched,
    name,
    width,
    color = "c-white",
    colorActive = "c-cyan",
    borderColor = "bc-white",
    borderColorActive = "bc-cyan",
    backgroundColor = "bg-transparent",
    backgroundColorActive = "bg-transparent",
  } = props;

  const [backgroundClass, setBackgroundClass] = useState(backgroundColor);
  const [borderClass, setBorderClass] = useState(borderColor);
  const [colorClass, setColorClass] = useState(color);

  const handleOnFocus = () => {
    setBackgroundClass(backgroundColorActive);
    setBorderClass(borderColorActive);
    setColorClass(colorActive);
  };

  const handleOnBlur = () => {
    setBackgroundClass(backgroundColor);
    setBorderClass(borderColor);
    setColorClass(color);
  };

  return (
    <>
      <FormikField
        {...props}
        className={`form-control my-3 p-5 d-inline-flex border-1 fs-1 w-100 w-lg-${
          width || 70
        } round-0rem bg-transparent ${colorClass} ${borderClass} ${backgroundClass}`}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
      />
      {errors[name] && touched[name] ? (
        <div className="c-navy-blue">{errors[name]}</div>
      ) : null}
    </>
  );
};

export default Field;
