import React from "react";
import { getBgColor } from "../utils/classPicker";

export const Section = ({
  bgColor = "",
  width,
  children,
  className = "",
  id,
  containerClassName = "",
  secondRowClassName = "",
}) => {
  if (className === "") {
    className = "overflow-hidden py-5rem";
  }

  if (containerClassName === "") {
    containerClassName = "container";
  }

  secondRowClassName =
    secondRowClassName === "" ? "row" : `row ${secondRowClassName}`;

  width = width ? `col-xl-${width} mx-auto` : "";

  return (
    <section className={`${className} ${getBgColor(bgColor)}`} id={id}>
      <div className={`${containerClassName}`}>
        <div className="row">
          <div className={`col-12 ${width}`}>
            <div className={`${secondRowClassName}`}>{children}</div>
          </div>
        </div>
      </div>
    </section>
  );
};
