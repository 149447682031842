import React, { useEffect, useState } from "react";
import UserVisitState, {
  UPDATE_SHOW_POPUP_SPECIAL_OFFER,
} from "../../state/UserVisitState";
import { getPathname } from "../../utils/location";
import { PopupSpecialOffer } from "../popup/PopupSpecialOffer";
const userVisitState = new UserVisitState();
const allowedPages = ["/oferta-promocyjna", "/oferta-promocyjna-2"];

export const usePopupSpecialOffer = () => {
  const [component, setComponent] = useState("");

  const handleMouseleave = () => {
    if (userVisitState.state.showPopupSpecialOffer) {
      userVisitState.updateShowPopupSpecialOffer(false);
    }
  };

  useEffect(() => {
    const pathname = getPathname();

    if (!allowedPages.includes(pathname.replace(/\/$/, ""))) {
      return;
    }

    userVisitState.attach(observer);

    if (userVisitState.state.showPopupSpecialOffer) {
      document.addEventListener("mouseleave", handleMouseleave);

      return () => document.removeEventListener("mouseout", handleMouseleave);
    }
  }, []);

  const observer = {
    id: Math.floor(Math.random() * 4000 + Date.now()),
    update: (data) => {
      switch (data.type) {
        case UPDATE_SHOW_POPUP_SPECIAL_OFFER:
          setComponent(<PopupSpecialOffer />);
          return;
        default:
          return;
      }
    },
  };

  return component;
};
