import React, { useState } from "react";
import { shorten } from "../../utils/text";
import { Field } from "formik";

export const ConsentCheckbox = ({
  name,
  errors,
  touched,
  text,
  borderColorActive,
  width,
  color = "c-white",
  colorActive = "c-cyan",
  borderColor = "bc-white",
  backgroundColor = "bg-transparent",
  backgroundColorActive = "bg-transparent",
    fontSize = "initial"
}) => {
  if (!borderColorActive) {
    borderColorActive = borderColor;
  }

  const [isChecked, setIsChecked] = useState(false);
  const [checkboxClass, setCheckboxClass] = useState(
    `${color} ${borderColor} ${backgroundColor}`
  );
  const [consentText, setConsentText] = useState(`${shorten(text, 15)}... `);

  const handleOnChange = () => {
    if (isChecked) {
      setIsChecked(false);
      setCheckboxClass(`${color} ${borderColor} ${backgroundColor}`);
      return;
    }

    setCheckboxClass(
      `checked ${colorActive} ${borderColorActive} ${backgroundColorActive}`
    );
    setIsChecked(true);
  };

  const handleReadMore = (self) => {
    self.target.className = "d-none";
    setConsentText(text);
  };

  return (
    <div className="pb-2rem">
      <div
        className={`d-flex justify-content-center w-100 w-lg-${width || 70}`}
      >
        <label className="d-flex mt-3" onChange={handleOnChange}>
          <div className={`d-flex checkbox rounded border ${checkboxClass}`}>
            <div className="tick" />
          </div>
          <Field className="d-none" type="checkbox" name={name} />
          <span className={`d-flex fs-0_875 mb-0 ml-3 cursor-pointer ${color}`}>
            <span style={{fontSize: fontSize}}>
              {consentText}
              <span onClick={handleReadMore}>Więcej &raquo;</span>
            </span>
          </span>
        </label>
      </div>
      {errors[name] && touched[name] ? (
        <div className="c-navy-blue">{errors[name]}</div>
      ) : null}
    </div>
  );
};
